<template>
  <app-layout class="bg-white">
    <div class="container mx-auto">
      <app-modal-audit-trails-detail
        :show="showModalDetail"
        @close="showModalDetail = false"
        :selectAuditTrail="selectAuditTrail"
      />
      <app-table
        :apiResponse="apiPaginationResponse.data"
        :loading="loading"
        @params-changed="params_changed"
        :filters="[
          {
            key: 'filter[event]',
            type: 'text',
            placeholder: $t('admin.audit.event'),
            value: '',
          },
          {
            key: 'filter[auditable_type]',
            type: 'text',
            placeholder: $t('admin.audit.auditable_type'),
            value: '',
          },
          {
            key: 'filter[name]',
            type: 'text',
            placeholder: $t('admin.audit.user_name'),
            value: '',
          },
          {
            key: 'filter[email]',
            type: 'text',
            placeholder: $t('admin.audit.user_email'),
            value: '',
          },
          {
            key: 'filter[business_name]',
            type: 'text',
            placeholder: $t('admin.audit.business_name'),
            value: '',
          },
          {
            key: 'filter[created_at_between]',
            type: 'date',
            value: '',
          },
        ]"
        @on-item-click="onItemClick"
      >
        <template v-slot:header>
          <th class="font-bold">{{ $t("admin.audit.event") }}</th>
          <th class="font-bold">{{ $t("admin.audit.auditable_type") }}</th>
          <th class="font-bold">{{ $t("admin.audit.via") }}</th>
          <th class="font-bold">{{ $t("admin.audit.type") }}</th>
          <th class="font-bold">{{ $t("admin.audit.type_details") }}</th>
          <th class="font-bold">{{ $t("admin.audit.created_at") }}</th>
        </template>
        <template v-slot:body="slotData">
          <td>
            <p class="capitalize">{{ slotData.model.event }}</p>
          </td>
          <td>
            {{ slotData.model.auditable_type }}
          </td>
          <td>
            {{ slotData.model.via }}
          </td>
          <td>
            {{ slotData.model.user_type }}
          </td>
          <td>
            {{ displayName(slotData.model) }}
          </td>
          <td>
            {{
              $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a")
            }}
          </td>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script>
export default {
  data() {
    return {
      tabs: [],
      current_tab: null,
      showModalDetail: false,
      filters: {},
      selectAuditTrail: null,
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["authStore/apiPaginationResponse"];
    },
    loading() {
      return this.$store.getters["authStore/loading"];
    },
  },
  mounted() {
    this.fetchAuditTrail();
  },
  methods: {
    fetchAuditTrail() {
      this.$store.dispatch("authStore/getAuditTrails", this.filters);
    },
    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectAuditTrail = data;
    },
    params_changed(filters, queryParams) {
      this.filters = filters;
      this.filterFormatDate(filters["filter[created_at_between]"]);
      this.fetchAuditTrail();
    },
    filterFormatDate(date) {
      let start = date?.start_date;
      let end = date?.end_date;

      if (date) this.filters["filter[created_at_between]"] = start + "," + end;
    },
    displayName(auditUser) {
      if (auditUser.user_type == null) return "System";

      return auditUser.user_type === "Modules\\Auth\\Entities\\User"
        ? auditUser.user?.name + " (" + auditUser.user?.email + ")"
        : auditUser?.integration?.business?.name;
    },
  },
};
</script>
